<template>
  <v-dialog
    v-model="dialog"
    max-width="400"
    persistent
  >
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn 
        v-bind="activatorProps"
        variant="text"
        icon="mdi-plus"
      />
    </template>

    <v-card
      title="Criar nova Fileira?"
    >
      <v-form ref="form">
        <v-text-field
          v-model="rowName"
          label="Nome"
          :rules="[v => !!v || 'Name is required']"
          hide-details
          required
        />
      </v-form>

      <template v-slot:actions>
        <v-spacer></v-spacer>

        <v-btn @click="dialog = false">
          Cancelar
        </v-btn>

        <v-btn 
          @click="onCreate"
          :disabled="rowAlreadyExist"
        >
          Criar
        </v-btn>
      </template>
    </v-card>
  </v-dialog> 
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    dialog: false,
    rowName: '',
    snackbar: {
      active: false,
      color: 'green',
      text: 'Fileira criada com sucesso.'
    }
  }),

  computed: {
    ...mapState('row', ['rows']),

    rowAlreadyExist() {
      return this.rows.find(row => row.row === this.rowName)
    }
  },
  
  methods: {
    ...mapActions('row', ['addRow']),

    async onCreate() {
      const { valid } = await this.$refs.form.validate()

      if (!valid) return;

      const { success } = await this.addRow({
        name: this.rowName
      })

      if (success) {
        this.snackbar.active = true;
        this.snackbar.text = 'Fileira criada com sucesso.';
        this.snackbar.color = 'green';
      } else {
        this.snackbar.text = 'Erro ao criar fileira.';
        this.snackbar.color = 'red';
        this.snackbar.active = true;
      }

      this.dialog = false;
      this.rowName = '';
      this.$emit('display-snackbar', this.snackbar)
    }
  }
}
</script>