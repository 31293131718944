import { createStore } from 'vuex';
import row from './row.store';
import spot from './spot.store';
import user from './user.store';

export const store = createStore({
  modules: {
    row,
    spot,
    user
  }
});