import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { store } from './store/store'
import { createApp } from 'vue'
import router from './routes/router';

loadFonts()

const app = createApp(App);
app.use(router)
app.use(vuetify);
app.use(store);
app.mount('#app')
