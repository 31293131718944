<template>
   <v-card class="pa-2">
    <v-form ref="registerForm" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="firstName"
            :rules="[rules.required]"
            label="First Name"
            maxlength="20"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="lastName"
            :rules="[rules.required]"
            label="Last Name"
            maxlength="20"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="password"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            :hint="passwordHin"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            block
            v-model="verify"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, passwordMatch]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Confirm Password"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
          <v-btn
            v-if="!isLoading"
            block
            color="red darken-2 white--text"
            @click="singUp"
            >Registrar</v-btn
          >
          <v-progress-circular
            v-else
            :size="35"
            color="error"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data: () => ({
    isLoading: false,
    valid: true,
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    verify: '',
    emailRules: [
      (v) => !!v || 'Required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    show1: false,
    rules: {
      required: (value) => !!value || 'Obrigatório.',
      min: (v) => (v
          && v.length >= 8
          && /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
            v,
          ))
        || 'Min 8 caracteres(1 maísculo, 1 minúsculo, 1 número, 1 caracter especial)',
    },
  }),

  computed: {
    passwordMatch() {
      return () => this.password === this.verify || 'Password must match';
    },

    passwordHin() {
      return 'Min 8 caracteres(1 maísculo, 1 minúsculo, 1 número, 1 caracter especial)';
    },
  },

  methods: {
    ...mapActions('user', ['register']),

    async singUp() {
      if (this.$refs.registerForm.validate()) {
        this.isLoading = true;
        const { success } = await this.register({
          email: this.email,
          password: this.password,
          firstName: this.firstName.toUpperCase(),
          lastName: this.lastName.toUpperCase()
        });
        this.isLoading = false;

        if (success) {
          this.$emit('signup-success');
          this.$router.push({ name: 'main' });
        } else {
          this.$emit('signup-fail');
        }
      }
    },
    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },
  }
}
</script>