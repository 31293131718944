import api from './axios/config';

export async function fetchRows() {
  try {
    const { data } = await api.get('row');

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}

export async function createRow(row) {
  try {
    const { data } = await api.post('row', row);

    return { success: true, data };
  } catch (error) {
    return { success: false, error };
  }
}